import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import(/* webpackChunkName: "about" */ '../views/main/layout'),
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '../views/main/home')
      },
      {
        path: 'receipt-summary',
        name: 'receipt-summary',
        meta: {
          title: '收货管理-收货汇总'
        },
        component: () => import(/* webpackChunkName: "receipt-summary" */ '../views/main/receipt-management/receipt-summary')
      },
      {
        path: 'website-admin',
        name: 'website-admin',
        meta: {
          title: '收货管理-网站管理'
        },
        component: () => import(/* webpackChunkName: "website-admin" */ '../views/main/receipt-management/website-admin')
      },
      {
        path: 'transcoding-disk',
        name: 'transcoding-disk',
        meta: {
          title: '出货管理-转码盘'
        },
        component: () => import(/* webpackChunkName: "transcoding-disk" */ '../views/main/shipment-management/transcoding-disk')
      },
      {
        path: 'fullcode-history',
        name: 'fullcode-history',
        meta: {
          title: '出货管理-满码历史'
        },
        component: () => import(/* webpackChunkName: "fullcode-history" */ '../views/main/shipment-management/fullcode-history')
      },
      {
        path: 'conversion-history',
        name: 'conversion-history',
        meta: {
          title: '出货管理-转换历史'
        },
        component: () => import(/* webpackChunkName: "conversion-history" */ '../views/main/shipment-management/conversion-history')
      },
      {
        path: 'shipment-details',
        name: 'shipment-details',
        meta: {
          title: '出货管理-出货明细'
        },
        component: () => import(/* webpackChunkName: "shipment-details" */ '../views/main/shipment-management/shipment-details')
      },
      {
        path: 'shipment-report',
        name: 'shipment-report',
        meta: {
          title: '出货管理-出货报表'
        },
        component: () => import(/* webpackChunkName: "shipment-report" */ '../views/main/shipment-management/shipment-report')
      },
      {
        path: 'manual-shipment',
        name: 'manual-shipment',
        meta: {
          title: '出货管理-手动出货'
        },
        component: () => import(/* webpackChunkName: "manual-shipment" */ '../views/main/shipment-management/manual-shipment')
      },
      {
        path: 'shipment-summary',
        name: 'shipment-summary',
        meta: {
          title: '出货管理-出货汇总'
        },
        component: () => import(/* webpackChunkName: "shipment-summary" */ '../views/main/shipment-management/shipment-summary')
      },
      {
        path: 'virtual-disk',
        name: 'virtual-disk',
        meta: {
          title: '虚盘'
        },
        component: () => import(/* webpackChunkName: "virtual-disk" */ '../views/main/virtual-disk/index')
      },
      {
        path: 'day-report',
        name: 'day-report',
        meta: {
          title: '报表-日报表'
        },
        component: () => import(/* webpackChunkName: "day-report" */ '../views/main/report/day-report')
      },
      {
        path: 'week-report',
        name: 'week-report',
        meta: {
          title: '报表-周报表'
        },
        component: () => import(/* webpackChunkName: "week-report" */ '../views/main/report/week-report')
      },
      {
        path: 'month-report',
        name: 'month-report',
        meta: {
          title: '报表-月报表'
        },
        component: () => import(/* webpackChunkName: "month-report" */ '../views/main/report/month-report')
      },
      {
        path: 'website-management',
        name: 'website-management',
        meta: {
          title: '网站设置-网站管理'
        },
        component: () => import(/* webpackChunkName: "website-management" */ '../views/main/website-settings/website-management')
      },
      {
        path: 'member-management',
        name: 'member-management',
        meta: {
          title: '网站设置-会员管理'
        },
        component: () => import(/* webpackChunkName: "member-management" */ '../views/main/website-settings/member-management')
      },
      {
        path: 'agent-management',
        name: 'agent-management',
        meta: {
          title: '网站设置-代理管理'
        },
        component: () => import(/* webpackChunkName: "agent-management" */ '../views/main/website-settings/agent-management')
      },
      {
        path: 'basic-settings',
        name: 'basic-settings',
        meta: {
          title: '系统设置-基本设置'
        },
        component: () => import(/* webpackChunkName: "basic-settings" */ '../views/main/system-settings/basic-settings')
      },
      {
        path: 'opening-setting',
        name: 'opening-setting',
        meta: {
          title: '系统设置-开盘设置'
        },
        component: () => import(/* webpackChunkName: "opening-setting" */ '../views/main/system-settings/opening-setting')
      },
      {
        path: 'lottery-number',
        name: 'lottery-number',
        meta: {
          title: '系统设置-开奖号码'
        },
        component: () => import(/* webpackChunkName: "lottery-number" */ '../views/main/system-settings/lottery-number')
      },
      {
        path: 'odds-change',
        name: 'odds-change',
        meta: {
          title: '系统设置-赔率变动'
        },
        component: () => import(/* webpackChunkName: "odds-change" */ '../views/main/system-settings/odds-change')
      },
      {
        path: 'fixed-price',
        name: 'fixed-price',
        meta: {
          title: '系统设置-定盘'
        },
        component: () => import(/* webpackChunkName: "fixed-price" */ '../views/main/system-settings/fixed-price')
      },
      {
        path: 'automatic-shipment',
        name: 'automatic-shipment',
        meta: {
          title: '系统设置-自动出货'
        },
        component: () => import(/* webpackChunkName: "automatic-shipment" */ '../views/main/system-settings/automatic-shipment')
      },
      {
        path: 'change-password',
        name: 'change-password',
        meta: {
          title: '系统设置-修改密码'
        },
        component: () => import(/* webpackChunkName: "change-password" */ '../views/main/system-settings/change-password')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */'../views/auth/login')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
